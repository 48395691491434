exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-health-jsx": () => import("./../../../src/pages/health.jsx" /* webpackChunkName: "component---src-pages-health-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-jsx": () => import("./../../../src/pages/latest.jsx" /* webpackChunkName: "component---src-pages-latest-jsx" */),
  "component---src-pages-recipes-jsx": () => import("./../../../src/pages/recipes.jsx" /* webpackChunkName: "component---src-pages-recipes-jsx" */),
  "component---src-pages-thoughts-jsx": () => import("./../../../src/pages/thoughts.jsx" /* webpackChunkName: "component---src-pages-thoughts-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-posts-jsx": () => import("./../../../src/templates/category-posts.jsx" /* webpackChunkName: "component---src-templates-category-posts-jsx" */),
  "component---src-templates-theme-posts-jsx": () => import("./../../../src/templates/theme-posts.jsx" /* webpackChunkName: "component---src-templates-theme-posts-jsx" */)
}

